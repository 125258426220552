.header-add-container {
  width: 100%;
  background-color: #8f9091;
}

.header-add {
  padding: 5px;
  display: block;
  line-height: 35px;
  text-align: center;
  width: 100%;
  background: 50% 100% / 50% 50% no-repeat radial-gradient(ellipse at bottom, #fff, transparent, transparent);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 120%;
  cursor: pointer;
  /* font-family: "Source Sans Pro", sans-serif; */
  animation: reveal 3000ms ease-in-out forwards 200ms,
    glow 2500ms linear infinite 2000ms;
}

@keyframes reveal {
  100% {
    background-size: 300% 300%;
  }
}

@keyframes glow {
  40% {
    text-shadow: 0 0 8px #fff;
  }
}

.topbar-button {
  position: relative !important;
  transition: all 0.3s ease !important;
  overflow: visible !important;
  z-index: 1 !important;
  border: none !important;
  background: white !important;
  color: #333 !important;
  height: 100% !important;
  min-height: 45px !important;
  padding: 8px 20px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.topbar-button::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(45deg, #ff0000, #00ff00, #0000ff, #ff00ff);
  background-size: 400% 400%;
  z-index: -1;
  border-radius: 8px;
  animation: gradient 3s ease infinite;
  padding: 2px;
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.topbar-button:hover {
  transform: scale(1.05) rotate(1deg) !important;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2) !important;
}

.topbar-button:active {
  transform: scale(0.95) !important;
}

@media (max-width: 450px) {
  .header-add {
    font-size: 4.5cqmin;
  }
}

@media (prefers-color-scheme: dark) {
  .header-add-container {
    background-color: #556f72;
  }
}